import { Injectable, Inject, OnInit, Renderer2, PLATFORM_ID, Input, inject, AfterViewInit } from "@angular/core";
import { environment as env } from '../environments/environment';
import { JssState } from './JssState';
import { ComponentRendering, RouteData, Field } from '@sitecore-jss/sitecore-jss-angular';
import { isPlatformServer } from "@angular/common";
import { AssetPipe } from "./pipes/asset.pipe";
import { JssMetaService } from './jss-meta.service';
import { DOCUMENT } from '@angular/common';

interface RouteFields {
    [name: string]: unknown;
    pageTitle: Field<string>;
  }
  
@Injectable()
export class PageMetaComponentBase implements OnInit, AfterViewInit {
    @Input() rendering: ComponentRendering;
    lang: string ;
    public route: RouteData<RouteFields>;
    public pageMetaData: PageMetaData = {};
    public createdLinks: HTMLLinkElement[] = [];
    public meta: JssMetaService = inject(JssMetaService);
    public jssState: JssState;
    public _isPlatformServer: boolean = false;

    constructor(public renderer: Renderer2,
                @Inject(PLATFORM_ID) public readonly platformId: string,
                @Inject(DOCUMENT) public document: Document) {

    }
    ngAfterViewInit(): void {
    }

    ngOnInit(): void {
        this._isPlatformServer = isPlatformServer(this.platformId);
    
        if (this.jssState) {
            this.pageMetaData.CurrentRoute = this.jssState.serverRoute != '/' ? ('/' + this.jssState.language + this.jssState.serverRoute) : '';
            if (this.route?.fields) {
                let routefields: any = this.route?.fields as any;
                this.pageMetaData.Id = this.route?.itemId
                this.pageMetaData.Author = routefields.Author?.value || '';
                this.pageMetaData.SiteName = routefields.SiteName?.value as string || '';
                this.pageMetaData.PageTitle = (routefields.MetaTitle?.value || '') + (this.pageMetaData.SiteName != '' ? ' | ' + this.pageMetaData.SiteName : '');
                this.pageMetaData.pageDescription = routefields.MetaDescription?.value as string || '';
                this.pageMetaData.PageType = routefields.MetaType?.value as string || '';
                this.pageMetaData.metaAbstract = routefields.MetaAbstract?.value as string || '';
                this.pageMetaData.metaKeywords = routefields.TargetedKeywords?.value as string || '';
                this.pageMetaData.CanonicalUrl = routefields.CanonicalURL?.value != '' ? (env.urlOrigin + '/' + this.jssState.language + "/" + routefields.CanonicalURL?.value as string) : '' /*(env.urlOrigin + this.pageMetaData.CurrentRoute)*/;
                this.pageMetaData.SocialMetaTitle = routefields.SocialMetaTitle?.value != '' ? routefields.SocialMetaTitle?.value as string : this.pageMetaData.PageTitle;
                this.pageMetaData.SocialMetaDescription = routefields.SocialMetaDescription?.value != '' ? routefields.SocialMetaDescription?.value as string : this.pageMetaData.pageDescription;
                this.pageMetaData.pageImage = routefields.MetaImage?.value?.src as string || '';
                this.pageMetaData.NoIndex = routefields.NoIndex?.value as boolean || false;
                this.pageMetaData.NoFollow = routefields.NoFollow?.value as boolean || false;
                this.pageMetaData.TwitterCard = routefields.TwitterCard?.value as string || 'summary';
                this.pageMetaData.Rating = routefields.Rating?.value as string || 'general';
                if (!Object.keys(this.createdLinks).length && !this._isPlatformServer) {
                    this.document.querySelector('link[rel="canonical"]')?.remove();
                    this.document.querySelector('link[rel="icon"]')?.remove();
                    this.document.querySelector('link[rel="shortlink"]')?.remove();
                    this.document.querySelector('link[rel="image_src"]')?.remove();
                }
            }

            Object.entries(this.createdLinks).map(link => {
                link[1].remove()
            });
            this.createdLinks = [];


            if (!this._isPlatformServer)
                (window as any)['dataLayer']?.push({
                    'event': "PageView",
                    'pagePath': this.pageMetaData.CurrentRoute,
                    'pageTitle': this.pageMetaData.PageTitle
                });
        }
        if (this.rendering?.fields) {
            let fields: any = this.rendering.fields as any;
            if (fields?.result && fields.result['PageMetadata']) {                

                this.pageMetaData.Id = fields.result['PageMetadata']?.Id || '';
                this.pageMetaData.Author = fields.result['PageMetadata']?.Author || '';
                this.pageMetaData.SiteName = fields.result['PageMetadata']?.SiteName || '';
                this.pageMetaData.PageTitle = (fields.result['PageMetadata']?.MetaTitle || '') + (this.pageMetaData.SiteName != '' ? ' | ' + this.pageMetaData.SiteName : '');
                this.pageMetaData.pageDescription = fields.result['PageMetadata']?.MetaDescription || '';
                this.pageMetaData.metaAbstract = fields.result['PageMetadata']?.metaAbstract || '';
                this.pageMetaData.metaKeywords = fields.result['PageMetadata']?.TargetedKeywords || '';
                this.pageMetaData.SocialMetaTitle = fields.result['PageMetadata']?.SocialMetaTitle != '' ? fields.result['PageMetadata'].SocialMetaTitle : fields.result['PageMetadata']?.MetaTitle;
                this.pageMetaData.SocialMetaDescription = fields.result['PageMetadata']?.SocialMetaDescription != '' ? fields.result['PageMetadata'].SocialMetaDescription : fields.result['PageMetadata']?.pageDescription;
                this.pageMetaData.pageImage = env.urlOrigin + fields.result['PageMetadata']?.MetaImage?.Href || '';
                this.pageMetaData.NoIndex = fields.result['PageMetadata']?.NoIndex == "1";
                this.pageMetaData.NoFollow = fields.result['PageMetadata']?.NoFollow == "1";
                this.pageMetaData.TwitterCard = fields.result['PageMetadata']?.TwitterCard || 'summary';

                this.pageMetaData.CurrentRoute = fields.result['PageMetadata']?.CurrentRoute;

                this.pageMetaData.CanonicalUrl = fields.result['PageMetadata']?.CanonicalUrl?.value != '' ? (env.urlOrigin + '/' + this.lang + fields.result['PageMetadata']?.CanonicalUrl?.value as string) : '' /*(env.urlOrigin + this.pageMetaData.CurrentRoute)*/;
                if(!this.pageMetaData.CanonicalUrl || this.pageMetaData.CanonicalUrl.includes("undefined") || this.pageMetaData.CanonicalUrl.includes("null")) this.pageMetaData.CanonicalUrl =  env.urlOrigin + this.pageMetaData.CurrentRoute;
                if (!Object.keys(this.createdLinks).length && !this._isPlatformServer) {
                    this.document.querySelector('link[rel="canonical"]')?.remove();
                    this.document.querySelector('link[rel="icon"]')?.remove();
                    this.document.querySelector('link[rel="shortlink"]')?.remove();
                    this.document.querySelector('link[rel="image_src"]')?.remove();
                }
            }
            else {
                return;
            }
        }
        this.setMetadata();        

    }

    setMetadata(): void {
        let favicon = new AssetPipe().transform('images/favicon.ico');
        this.createHeadLink('icon', favicon);

        if (this.pageMetaData.CanonicalUrl) {
            this.createHeadLink('canonical', this.pageMetaData.CanonicalUrl);
        }
        else {
            this.createHeadLink('canonical', env.urlOrigin + this.pageMetaData.CurrentRoute);
        }

        this.createHeadLink('shortlink', env.urlOrigin + this.pageMetaData.CurrentRoute);
        if( this.pageMetaData.pageImage){
            this.createHeadLink('image_src', this.pageMetaData.pageImage);
        }
        let year = new Date().getFullYear();
        let metaCopyright = "Copyright © 2001-"+ year +", Piraeus Bank Group"
        // meta
        this.meta.setTitle(this.pageMetaData.PageTitle);
        this.meta.addTag({ name: 'original-source', content: env.urlOrigin });
        this.meta.addTag({ name: 'description', content: this.pageMetaData.pageDescription });

        if(this.pageMetaData.metaAbstract){
            this.meta.addTag({ name: 'abstract', content: this.pageMetaData.metaAbstract });
        }
        
        if(this.pageMetaData.metaKeywords){
            this.meta.addTag({ name: 'keywords', content: this.pageMetaData.metaKeywords });
        }
        
        this.meta.addTag({ name: 'author', content: this.pageMetaData.Author });
        this.meta.addTag({ name: 'distribution', content: "global" });
        this.meta.addTag({ name: 'copyright', content: metaCopyright });
        if (this.pageMetaData.Rating) {
            this.meta.addTag({ name: 'rating', content: this.pageMetaData.Rating});
        }
        if(this.pageMetaData.Id){
            this.meta.addTag({ name: 'id', content: this.pageMetaData.Id});
        }

        if (this.pageMetaData.NoIndex || this.pageMetaData.NoFollow){
            this.meta.addTag({ name: 'robots', content: (this.pageMetaData.NoIndex ? 'noindex' : '') + ((this.pageMetaData.NoIndex && this.pageMetaData.NoFollow) ? ', ' : '') + (this.pageMetaData.NoFollow ? 'nofollow' : '') });
        }
        else{
            this.meta.addTag({ name: 'robots', content: "index, follow" });
        }
       
        this.meta.addTag({ name: 'og:site_name', content: this.pageMetaData.SiteName });
        this.meta.addTag({ name: 'og:url', content: env.urlOrigin + this.pageMetaData.CurrentRoute });
        if(this.pageMetaData.SocialMetaTitle) {
            this.meta.addTag({ name: 'og:title', content: this.pageMetaData.SocialMetaTitle });
        }
        else {
            this.meta.addTag({ name: 'og:title', content: this.pageMetaData.PageTitle });
        }
        if(this.pageMetaData.SocialMetaDescription) {
            this.meta.addTag({ name: 'og:description', content: this.pageMetaData.SocialMetaDescription });
        }
        else{
            this.meta.addTag({ name: 'og:description', content: this.pageMetaData.pageDescription });
        }
        if(this.pageMetaData.PageType){
            this.meta.addTag({ name: 'og:type', content: this.pageMetaData.PageType });
        }else{
            this.meta.addTag({ name: 'og:type', content: 'Website' });
        }
        this.meta.addTag({ name: 'og:image', content: this.pageMetaData.pageImage });
        this.meta.addTag({ name: 'og:image:url', content: this.pageMetaData.pageImage });
        this.meta.addTag({ name: 'og:image:secure_url', content: this.pageMetaData.pageImage });
        this.meta.addTag({ name: 'og:image:alt', content: 'Piraeusbank' });

        // // twitter
        if(this.pageMetaData.SocialMetaTitle) {
            this.meta.addTag({ name: 'twitter:title', content: this.pageMetaData.SocialMetaTitle });
        }
        else {
            this.meta.addTag({ name: 'twitter:title', content: this.pageMetaData.PageTitle });
        }
        if(this.pageMetaData.SocialMetaDescription) {
            this.meta.addTag({ name: 'twitter:description', content: this.pageMetaData.SocialMetaDescription });
        }        
        else{
            this.meta.addTag({ name: 'twitter:description', content: this.pageMetaData.pageDescription });
        }
        this.meta.addTag({ name: 'twitter:card', content: this.pageMetaData.TwitterCard });
        this.meta.addTag({ name: 'twitter:site', content: '@piraeusbank' });
        this.meta.addTag({ name: 'twitter:creator', content: '@piraeusbank' });
    }

    createHeadLink(rel: any, href: string, type?: string, preload: boolean = false) {
        if (this.createdLinks[rel] === undefined) {
            let link = this.renderer?.createElement('link') as HTMLLinkElement;
            if (!link) {
                return;
            }
            link.rel = rel || '';
            link.href = href || '';
            if (type) {
                link.type = type;
            }
            if (preload) {
                link.setAttribute('rel', 'preload');
                link.setAttribute('as', 'image');
            }

            this.renderer.appendChild(this.document.head, link);
            this.createdLinks[rel] = link;
        }
        else {
            this.createdLinks[rel].href = href || '';
        }
    }

    setState(state: JssState): void {
        this.jssState = state;
    }
    setLanguage(language: string): void {
        this.lang = language;
    }

}

export interface PageMetaData {
    Author?: string;
    SiteName?: string;
    CurrentRoute?: string;
    PageTitle?: string;
    PageImage?: string;
    PageType?: string;
    pageDescription?: string;
    pageImage?: string;
    metaAbstract?: string;
    metaKeywords?: string;
    CanonicalUrl?: string;
    SocialMetaTitle?: string;
    SocialMetaDescription?: string;
    NoIndex?: boolean;
    NoFollow?: boolean;
    TwitterCard?: string;
    Rating?: string;
    Id? : string;
}
