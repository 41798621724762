<header #Header>
    <div class="pb-header pb-fixed pb-transition-all pb-duration-300">
        <div #Alerts *ngIf="alert">
            <app-alert [data]="alert" (close)="CloseAlert()"></app-alert> 
        </div>
        <div class="pb-header-lvl-1 pb-relative pb-z-[1100] pb-transition-all pb-duration-300">
            <!-- DESKTOP LVL 1 -->
            <div class="pb-container lg:pb-flex pb-justify-between pb-hidden">
                <div class="pb-flex pb-gap-5">
                    <button class="hover:pb-scale-105 pb-content-center" type="button" *ngIf="otherLanguage" (click)="onLanguageChange($event,otherLanguage)" attr.aria-label="{{otherLanguage.Text}}">{{otherLanguage.Text}}</button>
                    <!-- Energy toggle Button -->
                    <div class="pb-flex pb-gap-2 pb-items-center" *ngIf="!rendering?.fields?.isHoldings?.value">
                        <label for="myCheckbox" class="pb-text-sm lg:pb-w-min xl:pb-w-max">{{'energy-saving' | translate}}</label>
                        <label for="myCheckbox" class="switch hover:pb-scale-105">
                          <input type="checkbox" id="myCheckbox"  (click)="openEnergyModal($event)" [checked]="energySave" aria-label="small-energy">
                            <span class="slider"></span>
                        </label>
                    </div>
                </div>
                <!-- Center Lvl 1 Items -->
                <nav class="pb-flex">
                    <a *ngFor="let section of centerlvl1Items; trackBy:trackBySection" 
                        hybridHref hreflang="{{language}}" href="{{section?.Href}}" attr.aria-label="{{section?.Text}}" [attr.target]="section?.ID == '00000000-0000-0000-0000-000000000000' ? '_blank' : ''"
                        class="lg:pb-w-min xl:pb-w-max lg:pb-py-2 lg:pb-px-4 xl:pb-py-4 xl:pb-px-5 lg:pb-text-sm lg:pb-font-bold lg:pb-content-center" 
                        [ngClass]="{'pb-section--active': section?.active}"><span class="lg:pb-relative">{{section?.Text}}</span></a>
                </nav>
                <!-- Right Side Lvl 1 Items -->
                <nav class="pb-flex">
                    <ng-container *ngIf="!rendering?.fields?.isHoldings?.value">
                        <a *ngFor="let sideItem of rightSidelvl1Items" 
                            hybridHref hreflang="{{language}}" href="{{sideItem?.Href}}" attr.aria-label="{{sideItem?.Text}}" [attr.target]="sideItem?.ID == '00000000-0000-0000-0000-000000000000' ? '_blank' : ''"
                            class="lg:pb-py-4 lg:pb-px-4 xl:pb-px-5 lg:pb-text-sm lg:pb-content-center"
                            [ngClass]="{'pb-section--active': sideItem?.active}"><span class="lg:pb-relative">{{sideItem?.Text}}</span>
                            
                        </a>
                        <!-- Piraeus Holdings Top Right Element with icon -->
                        <a  *ngIf="rendering?.fields?.PiraeusHoldingLink?.value?.href"
                            hybridHref hreflang="{{language}}" href="{{rendering?.fields?.PiraeusHoldingLink?.value?.href}}" attr.aria-label="{{rendering?.fields?.PiraeusHoldingLink?.value?.alt}}" [attr.target]="'_blank'"
                            class="lg:pb-text-sm lg:pb-pl-4 xl:pb-pl-5 pb-content-center">
                            <span class="lg:pb-relative pb-flex pb-items-center">{{rendering?.fields?.PiraeusHoldingLink?.value?.text}} <i class="pb-icon pb-icon-arrow-ur pb-text-i-24"></i></span>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="rendering?.fields?.isHoldings?.value">
                        <a *ngFor="let sideItem of rightSidelvl1Items" 
                            hybridHref hreflang="{{language}}" href="{{sideItem?.Href}}" attr.aria-label="{{sideItem?.Text}}" [attr.target]="sideItem?.ID == '00000000-0000-0000-0000-000000000000' ? '_blank' : ''"
                            class="lg:pb-pl-5 lg:pb-text-sm pb-content-center"
                            [ngClass]="{'pb-section--active': sideItem?.active}"><span class="lg:pb-relative pb-flex pb-items-center">{{sideItem?.Text}} <i class="pb-icon {{sideItem?.icon}} pb-text-i-24"></i></span>
                            
                        </a>
                    </ng-container>
                </nav>
            </div>
            <!-- MOBILE LVL 1 -->
            <div class="pb-flex pb-justify-between lg:pb-hidden pb-items-center pb-h-8">
                <!-- DropDown for lvl 1 items with isbuttoninMobile false, rightSide false and are not active -->
                <select *ngIf="!_isPlatformServer" class="pb-form-select select-showcase pb-mb-0" aria-label="select-lvl-1">
                    <!-- if none of the level 1 items is active then use the below to add a placeholder for the first item -->
                    <option *ngIf="noneIsActive" value="" disabled selected hidden>{{rendering?.fields?.result?.MenuJsonData?.level1?.items[0].Text}}</option>
                    <ng-container *ngFor="let option of rendering?.fields?.result?.MenuJsonData?.level1?.items">
                        <option *ngIf="option.active && !option?.isButtonInMobile && !option?.rightSide" value="" disabled selected hidden>
                            {{option?.Text}}
                        </option>
                        <ng-container *ngIf="!rendering?.fields?.isHoldings?.value">
                            <option *ngIf="!option?.active && !option?.isButtonInMobile && !option?.rightSide" value="{{option?.Href}}" >
                                {{option?.Text}}
                            </option>
                            <!-- Mobile dropdown menu piraeus holding link if it exists -->
                        </ng-container>
                        <ng-container *ngIf="rendering?.fields?.isHoldings?.value">
                            <option *ngIf="!option?.active" value="{{option?.Href}}" >
                                {{option?.Text}}
                            </option>
                        </ng-container>
                    </ng-container>
                    <option *ngIf="rendering?.fields?.PiraeusHoldingLink?.value?.href && !rendering?.fields?.isHoldings?.value" value="{{rendering?.fields?.PiraeusHoldingLink?.value?.href}}">
                        {{rendering?.fields?.PiraeusHoldingLink?.value?.text}}
                    </option>
                </select>
                <!-- <a hybridHref hreflang="{{language}}" href="" class="pb-btn pb-btn-transparent pb-ml-4 pb-text-nowrap">{{'e-banking' | translate}}</a> -->
                <div class="pb-container pb-w-fit pb-mr-0">
                    <button *ngIf="rendering?.fields?.SearchModule?.length>0" type="button"  aria-label="Open search modal" (click)="openSearchModal($event)"> 
                        <i class="pb-icon pb-text-i-32 pb-icon-search pb-icon-color-default"></i>
                    </button>
                    <!-- Support MenuItem link or others -->
                    <ng-container *ngFor="let item of rendering?.fields?.result?.MenuJsonData?.level1?.items">
                        <a *ngIf="item?.rightSide && !item.isButtonInMobile && !rendering?.fields?.isHoldings?.value" hybridHref hreflang="{{language}}" href="{{item?.Href}}" attr.aria-label="{{item?.Text}}"> 
                            <i class="pb-icon pb-text-i-32 pb-pl-5 {{item.icon}} pb-icon-color-default"></i>
                        </a>
                    </ng-container>
                </div>
            </div>
        </div>
        <!-- MOBILE LVL 2 -->
        <div class="pb-header-lvl-2-mobile lg:pb-hidden pb-header-lvl-2 pb-transition-all pb-duration-500">
            <div class="pb-justify-between pb-container">
                <ng-container *ngIf="isMainPage">
                    <h1 class="pb-cursor-pointer pb-py-4" aria-label="main-logo-mobile">
                        <a hybridHref hreflang="{{language}}" href="{{rendering?.fields?.LogoUrl?.value?.href}}" aria-label="main-logo-mobile" class="pb-cursor-pointer pb-py-4">
                            <div class="pb-logo" [ngClass]="{'pb-w-[290px] sm:pb-w-[360px]':rendering?.fields?.isHoldings?.value}" [innerHTML]="rendering?.fields?.Image?.value?.svgCode | TrustHtml"></div>
                        </a>
                    </h1>
                </ng-container>
                <ng-container *ngIf="!isMainPage">
                    <a hybridHref hreflang="{{language}}" href="{{rendering?.fields?.LogoUrl?.value?.href}}" aria-label="main-logo-mobile" class="pb-cursor-pointer pb-py-4">
                        <div class="pb-logo" [ngClass]="{'pb-w-[290px] sm:pb-w-[360px]':rendering?.fields?.isHoldings?.value}" [innerHTML]="rendering?.fields?.Image?.value?.svgCode | TrustHtml"></div>
                    </a>
                </ng-container>
                <div class="pb-flex pb-gap-4">
                    <!-- Burger Menu -->
                    <div class="pb-menu-toggle">
                        <input aria-label="pb-menu-toggle" id="pb-menu-burger" type="checkbox" [(ngModel)]="mobileMenuActive" (change)="freezeDoc(mobileMenuActive)">
                        <label class="toggle" for="pb-menu-burger">
                            <div class="bar bar--top"></div>
                            <div class="bar bar--middle"></div>
                            <div class="bar bar--bottom"></div>
                        </label>
                    </div>
                </div>
            </div>
            <div class="pb-menu-mobile pb-overflow-auto" [ngClass]="{'pb-menu--active' : mobileMenuActive}">
                <div class="pb-container pb-py-5 pb-self-start">
                    <nav class="pb-flex pb-flex-col">
                        <!-- Mobile Menu Accordion -->
                        <dl class="pb-c-menu-accordion" *ngFor="let menuItem of menuItems">
                            <dt class="pb-flex pb-items-center pb-text-h-xs-m pb-justify-between pb-pr-3 pb-cursor-pointer pb-py-2 ">
                                <a hybridHref hreflang="{{language}}" href="{{menuItem?.Href}}" attr.aria-label="{{menuItem?.Text}}" class="pb-text-start pb-w-full pb-menu-item pb-text-sm-m pb-font-bold">{{menuItem?.Text}}</a>
                                <div class="pb-text-end pb-flex pb-items-center pb-justify-end pb-pl-5 pb-pr-2 pb-py-2 pb-menu-border-subtle" [ngClass]="{'pb-opacity-0 pb-pointer-events-none' : !menuItem?.level3}" (click) = "openMobileAccordion($event, menuItem)">
                                    <i class="pb-icon pb-icon-chevron-down pb-font-bold pb-icon-color-default"></i>
                                </div>
                            </dt>
                            <dd>
                                <!-- Level 3 Mobile Menu -->
                                <nav class="pb-flex pb-flex-col">
                                    <a *ngFor="let menuSubItem of menuItem.level3" hybridHref hreflang="{{language}}" href="{{menuSubItem?.Href}}" attr.aria-label="{{menuSubItem?.title}}" class="pb-text-sm-m pb-py-4 pb-pl-5">{{menuSubItem?.title}}</a>
                                </nav>
                            </dd>
                        </dl>
                    </nav>
                    <!-- Button For Yellow or others menuItems that has rightSide true and isButton true -->
                    <ng-container *ngFor="let item of rendering?.fields?.result?.MenuJsonData?.level1?.items">
                        <a *ngIf="item?.rightSide && item.isButtonInMobile && item.icon !='pb-icon-yellow-fill'" hybridHref hreflang="{{language}}" href="{{item?.Href}}"
                            [attr.target]="item?.ID == '00000000-0000-0000-0000-000000000000' ? '_blank' : ''" 
                            class="pb-btn pb-btn-primary-brand pb-mt-5 pb-text-nowrap !pb-items-baseline">
                            <i class="!pb-text-color-brand pb-icon pb-text-i-32 {{item.icon}}"></i>
                            {{item?.Text}}
                        </a>
                        <a *ngIf="item?.rightSide && item.isButtonInMobile && (item.icon =='pb-icon-yellow-fill' || item.icon=='pb-icon-yellow')" 
                            hybridHref hreflang="{{language}}" href="{{item?.Href}}" 
                            [attr.target]="item?.ID == '00000000-0000-0000-0000-000000000000' ? '_blank' : ''" 
                            attr.aria-label="{{item?.Text}}"
                            class="pb-btn pb-btn-primary-brand pb-mt-5 pb-text-nowrap !pb-items-baseline pb-yellow-logo"
                            [ngClass]="{'!pb-hidden':dataSector=='private'}"
                            [innerHTML]="rendering?.fields?.YellowLogo?.value?.svgCode | TrustHtml">
                        </a>
                    </ng-container>
                    <button *ngIf="!rendering?.fields?.isHoldings?.value" type="button" class="pb-btn pb-btn-primary pb-mt-5 pb-text-nowrap pb-w-full e-banking-mobile-button" (click)="openEbankingModal($event)">
                        <i class="pb-icon pb-icon-protection pb-font-normal"></i>
                        {{'e-banking' | translate}}
                    </button>
                    <!-- Energy toggle Mobile Button -->
                    <div class="pb-flex pb-gap-2 pb-items-center pb-justify-center pb-my-6" *ngIf="!rendering?.fields?.isHoldings?.value">
                        <label for="myCheckbox1" class="pb-text-sm-m">{{'energy-saving' | translate}}</label>
                        <label for="myCheckbox1" class="switch">
                            <input type="checkbox" id="myCheckbox1" aria-label="small-energy1"  (click)="openEnergyModal($event)" [checked]="energySave">
                            <span class="slider"></span>
                        </label>
                    </div>
                    <button type="button" *ngIf="otherLanguage" (click)="onLanguageChange($event,otherLanguage)" attr.aria-label="{{otherLanguage.TextMobile}}" class="pb-block pb-w-full pb-my-6 pb-text-center">{{otherLanguage.TextMobile}}</button>
                    <!-- Emergency Text -->
                    <div class="pb-menu-promo-item pb-pt-5 pb-pb-5 pb-px-6 pb-mt-auto pb-w-full pb-text-center pb-font-bold">{{emergencyTitle}}</div>
                    <div class="pb-menu-promo-item pb-pb-8 pb-px-6 pb-mt-auto pb-w-full pb-text-center" [innerHTML]="emergencyDescription | TrustHtml"></div>
                    <!-- Promo for Mobile -->
                    <div *ngFor="let promo of promoItemsInMobile" class="pb-p-5 pb-flex pb-flex-col pb-menu-promo-item">
                        <img *ngIf="promo?.image?.Href" [defaultImage]="'images/default-image.png' | asset" [lazyLoad]="isMobile && promo?.imageMobile?.Href ? promo?.imageMobile?.Href : promo?.image?.Href" [alt]="isMobile ? promo?.imageMobile?.Alt : promo?.image?.Alt" class="pb-mb-4 pb-object-cover pb-h-14">
                        <div class="pb-font-bold">{{promo.title}}</div>
                        <div class="pb-text-sm-m pb-text-color-subtle pb-mt-1 pb-mb-1">{{promo.subtitle}}</div>
                        <a *ngFor="let link of promo.links" hybridHref hreflang="{{language}}" href="{{link?.Href}}" attr.aria-label="{{link?.Text}}" class="pb-underline pb-mt-2">{{link?.Text}}</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- DESKTOP LVL 2 -->
        <div class="pb-header-lvl-2-desktop pb-header-lvl-2 pb-container pb-hidden lg:pb-block pb-transition-all pb-duration-500">
            <div class="pb-flex pb-items-center pb-justify-between pb-h-full pb-mt-7 pb-relative">
                <div class="pb-flex pb-h-full pb-items-center pb-grow">
                    <ng-container *ngIf="isMainPage">
                        <h1 class="pb-cursor-pointer pb-px-4 pb-py-5" aria-label="main-logo-desktop">
                            <a hybridHref hreflang="{{language}}" href="{{rendering?.fields?.LogoUrl?.value?.href}}" aria-label="main-logo-desktop" class="">
                                <div class="pb-logo" [ngClass]="{'pb-w-[360px]':rendering?.fields?.isHoldings?.value}" [innerHTML]="rendering?.fields?.Image?.value?.svgCode | TrustHtml"></div>
                            </a>
                        </h1>
                    </ng-container>
                    <ng-container *ngIf="!isMainPage">
                        <a hybridHref hreflang="{{language}}" href="{{rendering?.fields?.LogoUrl?.value?.href}}" aria-label="main-logo-desktop" class="pb-cursor-pointer pb-px-4 pb-py-5">
                            <div class="pb-logo" [ngClass]="{'pb-w-[360px]':rendering?.fields?.isHoldings?.value}" [innerHTML]="rendering?.fields?.Image?.value?.svgCode | TrustHtml"></div>
                        </a>
                    </ng-container>

                    <!-- <nav class="pb-mx-auto" [ngClass]="{'pb-mx-auto':rendering?.fields?.isHoldings?.value}"> -->
                    <nav class="pb-mx-auto">
                        <ul class="pb-flex pb-items-center pb-justify-center pb-w-full pb-h-full" (mouseleave)="disableLevel3($event)">
                            <li *ngFor="let menuItem of menuItems; let i = index">
                                <div (mouseover)="enableLevel3($event)" class="pb-min-h-12 pb-flex pb-items-center pb-cursor-pointer pb-menu-item--parent">
                                    <a id="pb-menu-item-{{i}}" (focus)="enableLevel3($event)" hybridHref hreflang="{{language}}" href="{{menuItem?.Href}}" attr.aria-label="{{menuItem?.Text}}" class="pb-menu-item lg:pb-relative lg:pb-text-sm lg:pb-font-bold lg:pb-flex lg:pb-flex-col lg:pb-px-4 pb-justify-center pb-text-center" [ngClass]="{'pb-menu-item--active': menuItem?.active}">{{menuItem?.Text}}</a>
                                </div>
                                <div *ngIf="menuItem?.level3?.length > 0" class="pb-header-lvl-3">
                                    <div class="pb-header-lvl-3-inner-content">
                                        <button type="button" (click)="transferFocus(i+1)" class="pb-opacity-0 focus-visible:pb-opacity-100 pb-text-color-brand pb-px-8 lg:pb-text-lg pb-absolute pb-left-12 pb-top-1">{{'skip' | translate}}</button>
                                        <div class="lg:pb-text-lg pb-font-bold pb-pt-7 pb-px-12">{{menuItem.title}}</div>
                                        <div class="pb-flex pb-py-7 pb-px-12 pb-gap-6 pb-lvl-3-main-content">
                                            <div class="lg:pb-w-2/3">
                                                <nav class="lg:pb-columns-2 pb-gap-7">
                                                    <menu>
                                                        <li *ngFor="let menuSubItem of menuItem.level3" class="marker:pb-hidden marker:pb-content-none pb-my-6 first:pb-mt-0 last:pb-mb-0">
                                                            <a hybridHref hreflang="{{language}}" href="{{menuSubItem?.Href}}" class="pb-inline-flex  pb-cursor-pointer">
                                                                <i class="pb-icon {{menuSubItem.icon}} pb-text-i-32 pb-icon-color-menu pb-mr-3"></i>
                                                                <div class="pb-flex pb-flex-col pb-gap-2">
                                                                    <div class="lg:pb-text-md pb-font-bold">{{menuSubItem?.title}}</div>
                                                                    <div class="lg:pb-text-sm pb-text-color-subtle">{{menuSubItem?.subtitle}}</div>
                                                                    <a *ngFor="let link of menuSubItem.links" hybridHref hreflang="{{language}}" href="{{link?.Href}}" attr.aria-label="{{link?.Text}}" class="pb-underline">{{link?.Text}}</a>
                                                                </div>
                                                            </a>
                                                        </li>
                                                    </menu>
                                                </nav>
                                            </div>
                                            <div class="pb-w-1/3 pb-flex pb-flex-col pb-gap-5">
                                                <div *ngFor="let promo of menuItem.promoItems" class="pb-p-5 pb-flex pb-flex-col pb-menu-promo-item">
                                                    <img *ngIf="promo?.image?.Href" [defaultImage]="'images/default-image.png' | asset" [lazyLoad]="isMobile && promo?.imageMobile?.Href ? promo?.imageMobile?.Href : promo?.image?.Href" [alt]="isMobile ? promo?.imageMobile?.Alt : promo?.image?.Alt" class="pb-mb-4 pb-object-cover pb-h-14">
                                                    <div class="pb-font-bold">{{promo.title}}</div>
                                                    <div class="lg:pb-text-sm pb-text-color-subtle pb-mt-1 pb-mb-4">{{promo.subtitle}}</div>
                                                    <a *ngFor="let link of promo.links" hybridHref hreflang="{{language}}" href="{{link?.Href}}" attr.aria-label="{{link?.Text}}" class="pb-underline pb-mt-2">{{link?.Text}}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pb-menu-promo-item pb-py-5 pb-px-12 pb-mt-auto pb-w-full pb-text-center" >
                                        <strong>{{emergencyTitle}}</strong> 
                                        <span [innerHTML]="emergencyDescription | TrustHtml"></span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="pb-flex pb-items-center pb-p-[10.8px]">
                    <button id="openSearchButton" *ngIf="rendering?.fields?.SearchModule?.length>0" type="button"  aria-label="Open search modal" (click)="openSearchModal($event)" (focus)="disableLevel3($event)" class="hover:pb-scale-110"> 
                        <i class="pb-icon pb-text-i-32 pb-icon-search pb-px-5"></i>
                    </button>
                    <button *ngIf="!rendering?.fields?.isHoldings?.value" type="button" class="pb-btn pb-btn-primary pb-ml-4 pb-text-nowrap" (click)="openEbankingModal($event)"><i class="pb-icon pb-icon-protection pb-font-normal"></i>{{'e-banking' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</header>

<div *ngIf="!rendering?.fields?.isHoldings?.value" id="energySaveModal" class="pb-hidden pb-w-full lg:pb-h-full pb-inset-0 pb-fixed pb-z-[1200] pb-overflow-auto" [ngClass]="energySave ?'pb-bg-green-60':'pb-bg-warm-grey-60'">
    <div class="pb-flex pb-flex-col lg:pb-h-full" >
        <button type="button" aria-label="close-energymodal" class="pb-pr-3 lg:pb-pr-7 pb-pt-4 lg:pb-pt-6 pb-flex pb-ml-auto pb-w-fit pb-cursor-pointer" (click)="closeEnergyModal()">
            <i class="pb-icon pb-icon-check-x-dash pb-text-i-48 hover:pb-scale-110" [ngClass]="energySave ?'pb-text-white':'pb-text-grey-110'"></i>
        </button>
        <div class="pb-flex pb-flex-col lg:pb-flex-row pb-container pb-gap-4 lg:pb-gap-14 pb-h-full">
            <div class="lg:pb-w-1/2 pb-self-end">
                <div class="pb-flex pb-flex-col pb-gap-4"> 
                    <h2 class="pb-text-lg-m lg:pb-text-lg pb-font-bold" [ngClass]="energySave ?'pb-text-white':'pb-text-grey-110'">{{rendering?.fields?.EnergyModalTitle?.value}}</h2>
                    <div class="pb-text-d-2xl-m lg:pb-text-d-2xl pb-font-serif" [ngClass]="energySave ?'pb-text-white':'pb-text-green-70'">{{rendering?.fields?.EnergyModalBigTitle?.value}}</div>
                    <div class="pb-text-lg-m lg:pb-text-lg" [ngClass]="energySave ?'pb-text-white':'pb-text-green-70'">{{rendering?.fields?.EnergyModalDescription?.value}}</div>
                </div>
                <div class="pb-flex pb-flex-col lg:pb-flex-row pb-gap-5 pb-p-5 pb-border pb-group pb-mt-4 lg:pb-mt-9" [ngClass]="energySave ? 'pb-bg-green-60 pb-border-white' : 'pb-bg-olive-green-40 pb-border-olive-green-40'">
                    <div *scRichText="rendering?.fields?.SwitchBoxDescription" [ngClass]="energySave ?'pb-text-white':'pb-text-green-70'"></div>
                    <div class="pb-flex pb-justify-center">
                        <label for="energy-toggle-big" class="pb-inline-flex pb-items-center pb-cursor-pointer">
                            <input id="energy-toggle-big" class="pb-sr-only pb-peer" [(ngModel)]="energySave" (change)="initEnergyMode()" type="checkbox" aria-label="energy-toggle-big">
                            <div class="pb-group pb-peer pb-ring-0 pb-bg-white pb-border-2 pb-border-white pb-rounded-full pb-outline-none pb-duration-700 pb-relative
                            after:pb-duration-200 pb-w-14 pb-h-8 peer-checked:pb-bg-green-60 peer-focus:pb-outline-none  
                            after:pb-content-[''] after:pb-rounded-full after:pb-absolute 
                            after:pb-bg-olive-green-60 after:pb-outline-none after:pb-h-7 after:pb-w-7 after:pb-top-[2px] 
                            after:pb-left-[1px]  peer-checked:after:pb-translate-x-11 peer-hover:after:pb-scale-95
                            peer-checked:after:pb-bg-white peer-checked:pb-border-white
                            before:pb-content-['OFF'] before:pb-text-black before:pb-flex before:pb-items-center before:pb-h-full
                            before:pb-justify-end before:pb-ml-0 before:pb-mr-4 peer-checked:before:pb-text-white peer-checked:before:pb-justify-start
                            peer-checked:before:pb-content-['ON'] peer-checked:before:pb-ml-4 peer-checked:before:pb-mr-0">
                            </div>
                        </label>
                    </div>
                </div>
                <div class="pb-flex pb-flex-col lg:pb-flex-row pb-gap-1 pb-mt-4">
                    <div class="pb-px-3 pb-py-5 pb-flex pb-flex-col pb-gap-2 pb-items-center lg:pb-w-1/3" [ngClass]="energySave ? 'pb-bg-green-50' : 'pb-bg-warm-grey-30'">
                        <div class="pb-text-sm-m lg:pb-text-sm pb-text-center" [ngClass]="energySave ?'pb-text-white':'pb-text-green-70'">{{rendering?.fields?.CO2Label?.value}}</div>
                        <!-- <div class="pb-flex pb-gap-2">
                            <i class="pb-icon pb-icon-arrow-down pb-text-i-32" [ngClass]="energySave ? 'pb-text-grey-60' : 'pb-text-olive-green-70'"></i>
                            <div class="pb-text-h-sm-m lg:pb-text-h-sm" [ngClass]="energySave ?'pb-text-white':'pb-text-green-70'"><span class="pb-font-bold"><span class="first-number" style="--firstnumber:150;"></span></span>gr CO2 </div>
                        </div> -->
                    </div>
                    <div class="pb-px-3 pb-py-5 pb-flex pb-flex-col pb-gap-2 pb-items-center lg:pb-w-1/3" [ngClass]="energySave ? 'pb-bg-green-50' : 'pb-bg-warm-grey-30'">
                        <div class="pb-text-sm-m lg:pb-text-sm pb-text-center" [ngClass]="energySave ?'pb-text-white':'pb-text-green-70'">{{rendering?.fields?.WattsLabel?.value}}</div>
                        <!-- <div class="pb-flex pb-gap-2">
                            <i class="pb-icon pb-icon-arrow-down pb-text-i-32" [ngClass]="energySave ? 'pb-text-grey-70' : 'pb-text-olive-green-60'"></i>
                            <div class="pb-text-h-sm-m lg:pb-text-h-sm" [ngClass]="energySave ?'pb-text-white':'pb-text-green-70'"><span class="pb-font-bold"><span class="second-number" style="--secondnumber:3;"></span></span>Watts </div>
                        </div> -->
                    </div>
                    <div class="pb-px-3 pb-py-5 pb-flex pb-flex-col pb-gap-2 pb-items-center lg:pb-w-1/3" [ngClass]="energySave ? 'pb-bg-green-50' : 'pb-bg-warm-grey-30'">
                        <div class="pb-text-sm-m lg:pb-text-sm pb-text-center" [ngClass]="energySave ?'pb-text-white':'pb-text-green-70'">{{rendering?.fields?.KwLabel?.value}}</div>
                        <!-- <div class="pb-flex pb-gap-2">
                            <i class="pb-icon pb-icon-arrow-down pb-text-i-32" [ngClass]="energySave ? 'pb-text-grey-70' : 'pb-text-olive-green-60'"></i>
                            <div class="pb-text-h-sm-m lg:pb-text-h-sm" [ngClass]="energySave ?'pb-text-white':'pb-text-green-70'"><span class="pb-font-bold"><span class="third-number" style="--thirdnumber:421;"></span></span>kW </div>
                        </div> -->
                    </div>
                </div>
                <button type="button" class="pb-link pb-link-energy-modal pb-my-6" (click)="closeEnergyModal()">{{'back-to-main-page' | translate}}</button>
            </div>
            <div class="lg:pb-w-1/2 pb-relative">
                <img offset="100" [defaultImage]="'images/default-image.png' | asset" [lazyLoad]="energySave ? rendering?.fields?.EnergyModalEnergySaveImage?.value?.src :rendering?.fields?.EnergyModalImage?.value?.src" 
                    [alt]="energySave ? rendering?.fields?.EnergyModalEnergySaveImage?.value?.alt :rendering?.fields?.EnergyModalImage?.value?.alt" 
                    class="lg:pb-absolute lg:pb-bottom-[-8px] lg:pb-right-0 !pb-block"/>
            </div>
        </div>
        <div class=" pb-w-full " [ngClass]="energySave ? 'pb-bg-green-60 pb-text-white' : 'pb-bg-white pb-text-green-70'"> 
            <div class="pb-container pb-py-4 pb-text-lg-m lg:pb-text-lg pb-flex pb-flex-col lg:pb-flex-row">
                {{rendering?.fields?.BottomText?.value}}
                <a hybridHref hreflang="{{language}}" href="{{rendering?.fields?.BottomLink?.value?.href}}" class="lg:pb-ml-auto pb-underline pb-cursor-pointer pb-mt-5 lg:pb-mt-0">{{rendering?.fields?.BottomLink?.value?.text}}</a>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="rendering?.fields?.SearchModule?.length>0">
    <app-search [rendering]="rendering?.fields?.SearchModule[0]" [language]="language"></app-search>
</ng-container>

<ng-container *ngIf="rendering?.fields?.EbankingModal && rendering?.fields?.EbankingModal?.length == 1 ">
    <app-ebanking [rendering]="rendering?.fields?.EbankingModal[0]"></app-ebanking>
</ng-container>