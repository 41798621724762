import { Component, OnInit, Input,ViewChild, Inject, PLATFORM_ID, ElementRef, AfterViewInit } from "@angular/core";
import { ComponentRendering } from "@sitecore-jss/sitecore-jss-angular";
import Swiper from "swiper";
import { Pagination, Autoplay, A11y } from 'swiper/modules';
import { isPlatformServer } from "@angular/common";
import { ThemeService } from "../../services/theme.service";
import { Subscription } from "rxjs";

@Component({
    selector: "app-hero-landing",
    templateUrl: "./hero-landing.component.html",
})
export class HeroLandingComponent implements OnInit, AfterViewInit {
    @Input() rendering: ComponentRendering;
    swiper: Swiper;
    _isPlatformServer: boolean = false;
    energySaveMode : boolean;
    energySaveSubscription: Subscription;
    filteredCards: any[] = [];
    @ViewChild('HeroLandingSwiper', { static: false }) swiperSlider!: ElementRef;

    constructor(
        @Inject(PLATFORM_ID) public readonly platformId: string,
        private themeService: ThemeService,
    ) {
        platformId;
    }

    ngOnInit() {
        this._isPlatformServer = isPlatformServer(this.platformId);
        // if (!this._isPlatformServer) {
            this.filteredCards = (this.rendering.fields.Cards as any).filter((card:any) => card.fields?.Title?.value);
            this.energySaveSubscription = this.themeService.isEnegySaveModeEnabled().subscribe(energySave => {
                this.energySaveMode = energySave;
            });
          
        // }
    }

    ngAfterViewInit(): void {
        if ((this.rendering.fields as any)?.Cards.length > 1) {
            this.swiper = new Swiper(this.swiperSlider?.nativeElement, {
                slidesPerView: 1,
                freeMode: true,
                centeredSlides: false,
                spaceBetween: 0,
                grabCursor: true,
                autoHeight:false,
                loop:true,
                autoplay: parseFloat((this.rendering.fields?.TimeRotation as any)?.value) && parseFloat((this.rendering.fields?.TimeRotation as any)?.value) > 0 ? 
                {
                  delay: parseFloat((this.rendering.fields?.TimeRotation as any)?.value)*1000,
                } : false ,
                speed:1200,
                pagination: {
                  el: ".swiper-pagination",
                  clickable: true,
                },
                a11y:{
                    enabled:true
                },
                modules:[Pagination, Autoplay, A11y]
            });
        }
    }
    ngOnDestroy(): void {
        if (!this._isPlatformServer) {
            if(this.energySaveSubscription){
                this.energySaveSubscription.unsubscribe();
            }
        }
    }

}
